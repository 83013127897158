<template>
    <BT-Blade-Item
        bladeName="customer-group"
        :bladesData="bladesData"
        navigation="customer-groups"
        :trackIgnore="['supplyAgreements']">
        <template v-slot="{ item, data }">

            <BT-Field-String
                v-model="item.groupName"
                label="Group Name"
                :isEditing="data.isEditing || data.isNew" />

            <v-expansion-panels
                v-if="!data.isNew"
                v-model="panelV"
                height="60vh">

                <BT-Blade-Expansion-Links
                    height="60vh"
                    :itemProperties="['ID','Buyer']"
                    label="Customers"
                    linkGetURL="/get/Links?SupplyAgreementGroupID={id}"
                    linkNavigation="customer-groups"
                    manyText="buyer.companyName"
                    manyProp="supplyAgreementID"
                    manyToMany
                    manyNavigation="customers"
                    :searchProps="['buyer.companyName']"
                    :singleID="item.id"
                    singleProp="supplyAgreementGroupID"
                    startOpen>
                    <template v-slot:settings-right="{ items }">
                        <v-btn icon small @click.stop="exportToCSV(items)">
                            <v-icon small>mdi-table-arrow-right</v-icon>
                        </v-btn>
                    </template>
                </BT-Blade-Expansion-Links>

            </v-expansion-panels>
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Customer-Group-Blade',
    components: {
        BTBladeExpansionLinks: () => import('~components/BT-Blade-Expansion-Links.vue')
    },
    data: function() {
        return {
            panelV: 0
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        exportToCSV(items) {
            var manyItems = items.map(x => x.manyItem.buyer);
            this.generateCSVFile(manyItems, 'csvData.csv', ['companyName', 'emailAddressesAsCustomer', 'primaryEmail']);
        }
    }
}
</script>